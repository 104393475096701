import React from 'react'
import {} from 'styled-components/macro'
import { Button } from '@musicworldmedia/ui-kit'

import logo from '../../images/logo-white.png'
import team from '../../images/mwm-team.jpg'
import config from '../../config'

const Login = () => {
  return (
    <div
      css={`
        height: 100%;
        background-image: url(${team});
        background-size: cover;
        background-position: center;
        color: white;
      `}
    >
      <div
        css={`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.85);
          padding: 1rem;
          box-sizing: border-box;
        `}
      >
        <img
          css={`
            width: 100%;
            max-width: 500px;
            margin-bottom: 4rem;
          `}
          src={logo}
          alt=""
        />
        <a
          href={`${config.AUTHORIZATION_API}/google/redirect?client=${config.CLIENT_ID}&redirect_uri=${window.location.origin}${window.location.pathname}`}
        >
          <Button>Log In</Button>
        </a>
      </div>
    </div>
  )
}

export default Login
