import React, { useEffect, Suspense, lazy } from 'react'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { History } from 'history'
import {} from 'styled-components/macro'
import { useQuery, useIsFetching, useQueryCache } from 'react-query'
import { useCookies } from 'react-cookie'
import qs from 'qs'
import { Layout, Loading } from '@musicworldmedia/ui-kit'
import {
  PeopleAltOutlined,
  PermMediaOutlined,
  LibraryBooksOutlined,
  AssignmentLateOutlined,
  GroupWorkOutlined,
  AccountBoxOutlined,
  VpnKeyOutlined,
  FreeBreakfastOutlined,
  QueuePlayNextOutlined,
  VideocamOutlined,
  InsertDriveFileOutlined,
  CreditCardOutlined,
  FolderOpenOutlined,
  StorageOutlined,
  FeaturedPlayListOutlined,
  AccountCircleOutlined,
  ArtTrackOutlined,
  HomeOutlined,
  LocalOfferOutlined,
  GetAppOutlined,
  CompareOutlined,
  PollOutlined,
} from '@material-ui/icons'
import * as Sentry from '@sentry/react'

import { fetchMe } from './features/common/services'
import ErrorFallback from './components/ErrorFallback'
import Home from './features/common/components/Home'
import Login from './features/login/Login'
import AuthorizationsProvider from './providers/Authorizations'
import { GetAppID } from './features/versions/utils/appID'
const VersionsRouter = lazy(() => import('./features/versions/Router'))
const CatalogRouter = lazy(() => import('./features/app-catalog/Router'))
const IamRouter = lazy(() => import('./features/iam/Router'))
const IapRouter = lazy(() => import('./features/iap/Router'))
const CreativityRouter = lazy(() => import('./features/creativity/Router'))
const IntranetRouter = lazy(() => import('./features/intranet/Router'))
const PublishingPlatformRouter = lazy(() => import('./features/publishing-platform/Router'))

const getMenu = (authorizations: string[], history: History) => {
  const appID = GetAppID(history)
  return [
    {
      category: 'IAM',
      links: [
        authorizations.includes('auth.user.read') && {
          label: 'Users',
          icon: PeopleAltOutlined,
          to: '/iam/users',
        },
        authorizations.includes('auth.group.read') && {
          label: 'Groups',
          icon: GroupWorkOutlined,
          to: '/iam/groups',
        },
        authorizations.includes('auth.role.read') && {
          label: 'Roles',
          icon: AccountBoxOutlined,
          to: '/iam/roles',
        },
        authorizations.includes('auth.authorization.read') && {
          label: 'Authorizations',
          icon: VpnKeyOutlined,
          to: '/iam/authorizations',
        },
      ].filter(Boolean),
    },
    {
      category: 'Versions',
      links: [
        authorizations.includes('version.application.read') && {
          label: 'Summary',
          icon: HomeOutlined,
          to: `/app/summary?app_id=${appID}`,
        },
        authorizations.includes('version.application.read') && {
          label: 'Versions',
          icon: LocalOfferOutlined,
          to: `/app/versions?app_id=${appID}`,
        },
        authorizations.includes('version.application.read') && {
          label: 'Builds',
          icon: GetAppOutlined,
          to: `/app/builds?app_id=${appID}`,
        },
        authorizations.includes('version.application.read') && {
          label: 'Competitors',
          icon: CompareOutlined,
          to: `/app/competitors/versions?app_id=${appID}`,
        },
      ].filter(Boolean),
    },
    {
      category: 'Creativity',
      links: [
        authorizations.includes('ccc.profile.read') && {
          label: 'Profiles',
          icon: PeopleAltOutlined,
          to: '/creativity/profiles',
        },
        authorizations.includes('ccc.project.read') && {
          label: 'Projects',
          icon: PermMediaOutlined,
          to: '/creativity/projects',
        },
        authorizations.includes('ccc.post.read') && {
          label: 'Posts',
          icon: LibraryBooksOutlined,
          to: '/creativity/posts',
        },
        authorizations.includes('ccc.ticket.read') && {
          label: 'Tickets',
          icon: AssignmentLateOutlined,
          to: '/creativity/tickets',
        },
      ].filter(Boolean),
    },
    {
      category: 'Catalog',
      links: [
        authorizations.includes('storage-catalog.catalog.read') && {
          label: 'Catalog',
          icon: ArtTrackOutlined,
          to: '/catalog/list',
        },
        authorizations.includes('storage-catalog.author.read') && {
          label: 'Author',
          icon: AccountCircleOutlined,
          to: '/catalog/author',
        },
        authorizations.includes('storage-catalog.collection.read') && {
          label: 'Collection',
          icon: FeaturedPlayListOutlined,
          to: '/catalog/collection',
        },
        authorizations.includes('storage-catalog.content.read') && {
          label: 'Content',
          icon: StorageOutlined,
          to: '/catalog/content',
        },
      ].filter(Boolean),
    },
    {
      category: 'IAP',
      links: [
        authorizations.includes('iap.in-apps.read') && {
          label: 'In-Apps',
          icon: CreditCardOutlined,
          to: '/iap/in-apps',
        },
        authorizations.includes('iap.packs.read') && {
          label: 'Packs',
          icon: FolderOpenOutlined,
          to: '/iap/packs',
        },
      ].filter(Boolean),
    },
    {
      category: 'Publishing Platform',
      links: authorizations.includes('gpp.application.read')
        ? [
            { label: 'Submissions', icon: QueuePlayNextOutlined, to: '/publishing-platform/submissions' },
            { label: 'Test Results', icon: PollOutlined, to: '/publishing-platform/test-results' },
          ]
        : [],
    },
    {
      category: 'Intranet',
      links: [
        {
          label: 'Conferences',
          icon: VideocamOutlined,
          to: '/intranet/conferences',
        },
        authorizations.includes('intranet.coffee.write') && {
          label: 'Coffee Roulette',
          icon: FreeBreakfastOutlined,
          to: '/intranet/coffee',
        },
        authorizations.includes('doc.documentation.read') && {
          label: 'API Docs',
          icon: InsertDriveFileOutlined,
          to: '/intranet/documentation',
        },
      ].filter(Boolean),
    },
  ].filter(({ links }) => links.length > 0)
}

const App = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['x-mwm-access-token', 'x-mwm-refresh-token'])
  const isFetching = useIsFetching()
  const location = useLocation()
  const history = useHistory()
  const queryCache = useQueryCache()
  const { access_token } = qs.parse(location.search, { ignoreQueryPrefix: true })
  const { data: user, isLoading: profileLoading } = useQuery(['profile'], fetchMe, {
    enabled: cookies['x-mwm-access-token'],
    retry: false,
    refetchOnWindowFocus: false,
    onError: () => removeCookie('x-mwm-access-token', { path: '/' }),
  })

  useEffect(() => {
    if (access_token) {
      setCookie('x-mwm-access-token', access_token, { path: '/' })
      history.replace(location.pathname)
    }
  }, [access_token, cookies, setCookie, history, location.pathname])

  if (profileLoading || access_token) return null

  return (
    <div
      css={`
        height: 100%;
        width: 100%;
      `}
    >
      {user ? (
        <AuthorizationsProvider authorizations={user.authorizations}>
          <Layout
            header={{
              title: 'Back-Office',
              environment: process.env.REACT_APP_ENV_NAME ?? 'dev',
            }}
            user={{ name: user.name, avatar: user.picture }}
            logout={() => {
              removeCookie('x-mwm-access-token', { path: '/' })
              queryCache.removeQueries(['profile'])
            }}
            isLoading={!!isFetching}
            // @ts-ignore
            menu={getMenu(user.authorizations, history)}
          >
            <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
              <Suspense fallback={<Loading />}>
                <Switch>
                  <Route path="/iam" component={IamRouter} />
                  <Route path="/iap" component={IapRouter} />
                  <Route path="/creativity" component={CreativityRouter} />
                  <Route path="/catalog" component={CatalogRouter} />
                  <Route path="/app" component={VersionsRouter} />
                  <Route
                    path="/publishing-platform"
                    render={() => <PublishingPlatformRouter currentUserId={user.user_id} />}
                  />
                  <Route path="/intranet" component={IntranetRouter} />
                  <Route path="/iap" component={IapRouter} />
                  <Route path="/">
                    <Home name={user.name} />
                  </Route>
                </Switch>
              </Suspense>
            </Sentry.ErrorBoundary>
          </Layout>
        </AuthorizationsProvider>
      ) : (
        <Login />
      )}
    </div>
  )
}

export default App
