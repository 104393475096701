import { History } from 'history'

// edjing
const defaultAppID = 'bd5e4411-a6b2-459f-898d-604bd2cf3c5c'

export const GetAppID = (history: History): string => {
  const query = new URLSearchParams(history.location.search)
  let appID = query.get('app_id')

  if (!appID || appID === '') {
    appID = sessionStorage.getItem('globalSelectedApp') ?? defaultAppID
    query.set('app_id', appID)
    history.location.search = query.toString()
  }

  sessionStorage.setItem('globalSelectedApp', appID)

  return appID
}

export const SetAppID = (appID: string, history: History) => {
  const query = new URLSearchParams(history.location.search)
  sessionStorage.setItem('globalSelectedApp', appID)

  query.set('app_id', appID)
  history.push({
    pathname: history.location.pathname,
    search: query.toString(),
  })
}
