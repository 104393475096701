import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import Cookies from 'universal-cookie'
import sha1 from 'js-sha1'

import packageJson from '../../package.json'
import dateUtil from './date'

const cookies = new Cookies()

export const request = async <T>(config: AxiosRequestConfig) => {
  const response = await axios.request<T>({
    method: 'GET',
    ...config,
    headers: {
      ...config.headers,
      Authorization: cookies.get('x-mwm-access-token'),
    },
  })

  return response.data
}

export default request

export const requestPost = async <T>(config: AxiosRequestConfig) => {
  const response = await axios.request<T>({
    method: 'POST',
    ...config,
    headers: {
      ...config.headers,
      Authorization: cookies.get('x-mwm-access-token'),
    },
  })

  return response.data
}

export const synchronousPostRequest = async <T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  return axios.request<T>({
    method: 'POST',
    ...config,
    headers: {
      ...config.headers,
      Authorization: cookies.get('x-mwm-access-token'),
    },
  })
}

export const requestDelete = async <T>(config: AxiosRequestConfig) => {
  const response = await axios.request<T>({
    method: 'DELETE',
    ...config,
    headers: {
      ...config.headers,
      Authorization: cookies.get('x-mwm-access-token'),
    },
  })

  return response.data
}

export const iapRequest = async <T>(config: AxiosRequestConfig) => {
  const currentHour = dateUtil.utc().format().substring(0, 13)

  const response = await axios.request<T>({
    method: 'GET',
    ...config,
    headers: {
      ...config.headers,
      'MWM-AUTH': sha1(`${currentHour}MWM_Secure`),
      'MWM-APP-VERSION': packageJson.version,
      'MWM-USER': 'backoffice-3c',
    },
  })
  return response.data
}

export const mwmAuthRequest = async <T>(config: AxiosRequestConfig) => {
  const authHeader = sha1(dateUtil.utc().format().substring(0, 13) + 'MWM_Secure')

  const response = await axios.request<T>({
    method: 'GET',
    ...config,
    headers: {
      ...config.headers,
      'MWM-AUTH': `${authHeader}`,
    },
  })
  return response.data
}
