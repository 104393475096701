import React, { useContext } from 'react'

const AuthorizationsContext = React.createContext<string[]>([])

interface Props {
  children: React.ReactNode
  authorizations: string[]
}

const AuthorizationsProvider = ({ children, authorizations }: Props) => {
  return <AuthorizationsContext.Provider value={authorizations}>{children}</AuthorizationsContext.Provider>
}

export const useAuthorizations = () => {
  return useContext(AuthorizationsContext)
}

export default AuthorizationsProvider
