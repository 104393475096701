import request from '../../utils/request'
import config from '../../config'

export const fetchApps = async () => {
  return request({
    url: `https://admin-dot-mwm-ua-scheduling.appspot.com/applications/list`,
  })
}

export const fetchMe = async (key: string) => {
  return request<UserInfos | null>({
    url: `${config.AUTHENTICATION_API}/me`,
  })
}

export const fetchUser = async (key: string, id: string) => {
  return request<PublicUserInfos | null>({
    url: `${config.AUTHENTICATION_API}/user/${id}`,
  })
}
