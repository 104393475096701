import React from 'react'
import ReactDOM from 'react-dom'
import { theme, GlobalStyles } from '@musicworldmedia/ui-kit'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import * as Sentry from '@sentry/react'
import 'react-toastify/dist/ReactToastify.css'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import ErrorFallback from './components/ErrorFallback'
import App from './App'

if (
  process.env.REACT_APP_ENV_NAME === 'prod' &&
  process.env.REACT_APP_SENTRY_DSN &&
  process.env.REACT_APP_SENTRY_RELEASE
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  })
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
    <ThemeProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <Router>
          <App />
        </Router>
      </DndProvider>

      <GlobalStyles />
      <ToastContainer
        position="bottom-right"
        autoClose={3200}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        draggable
        pauseOnHover
      />
    </ThemeProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
)

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister()
  })
}
