const dev = {
  IAP_API: 'https://dev-dot-admin-dot-mwm-iap.appspot.com',
  CREATIVITY_API: 'https://dev-dot-admin-dot-mwm-crea-community.appspot.com',
  AUTHENTICATION_API: 'https://dev-dot-user-dot-mwm-auth.appspot.com',
  AUTHORIZATION_API: 'https://dev-dot-authorization-dot-mwm-auth.appspot.com',
  ADMIN_AUTHORIZATION_API: 'https://dev-dot-admin-dot-mwm-auth.ew.r.appspot.com',
  BOT_API: 'https://dev-dot-bots-dot-mwm-home.appspot.com',
  GPP_API: 'https://dev-dot-api-dot-mwm-gpp.ew.r.appspot.com',
  TEMP_GPP_API: 'https://dev-dot-api-dot-mwm-gpp.ew.r.appspot.com',
  GPP_PROTOS_TEST_API: 'https://dev-dot-protos-dot-games-prototypes-backend.appspot.com',
  DOCUMENTATION_API: 'https://api.doc.staging.mwminfra.com',
  CATALOG_API: 'https://dev-dot-catalog-dot-mwm-storage.appspot.com',
  STORAGE_API: 'https://dev-dot-files-dot-mwm-storage.appspot.com',
  STORE_API: 'https://dev-dot-store-dot-mwm-version.appspot.com',
  VERSIONS_ADMIN: 'https://dev-dot-admin-dot-mwm-version.appspot.com',
  VERSIONS_API: 'https://dev-dot-api-dot-mwm-version.appspot.com',
}

const prod = {
  IAP_API: 'https://admin-dot-mwm-iap.appspot.com',
  CREATIVITY_API: 'https://admin-dot-mwm-crea-community.appspot.com',
  AUTHENTICATION_API: 'https://user-dot-mwm-auth.appspot.com',
  AUTHORIZATION_API: 'https://authorization-dot-mwm-auth.appspot.com',
  ADMIN_AUTHORIZATION_API: 'https://admin-dot-mwm-auth.ew.r.appspot.com',
  BOT_API: 'https://bots-dot-mwm-home.appspot.com',
  GPP_API: 'https://api-dot-mwm-gpp.ew.r.appspot.com',
  TEMP_GPP_API: 'https://dev-dd-dot-api-dot-mwm-gpp.ew.r.appspot.com',
  GPP_PROTOS_TEST_API: 'https://protos-dot-games-prototypes-backend.appspot.com',
  DOCUMENTATION_API: 'https://api.doc.mwminfra.com',
  CATALOG_API: 'https://catalog-dot-mwm-storage.appspot.com',
  STORAGE_API: 'https://files-dot-mwm-storage.appspot.com',
  STORE_API: 'https://store-dot-mwm-version.appspot.com',
  VERSIONS_ADMIN: 'https://admin-dot-mwm-version.appspot.com',
  VERSIONS_API: 'https://api-dot-mwm-version.appspot.com',
}

const config = process.env.REACT_APP_ENV_NAME === 'prod' ? prod : dev

export default {
  ...config,
  CLIENT_ID: 'mwm-backoffice',
  ACCESS_TOKEN_COOKIE: config.ACCESS_TOKEN_COOKIE,
}
