import React from 'react'
import {} from 'styled-components/macro'

interface Props {
  name: string
}

const Home = ({ name }: Props) => (
  <div
    css={`
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  >
    <h1
      css={`
        font-size: 1.5rem;
        margin: 0;
      `}
    >
      Bonjour {name}
    </h1>
  </div>
)

export default Home
